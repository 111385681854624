<template>
  <div>
    <b-link :id="`cooperative_inactive_${item.id}`">
      <span
        :class="[
          get(item, 'attributes.status') == 'Active' && get(item, 'attributes.step_status', 0) >= 3
            ? 'label-light-success'
            : 'label-light-danger',
        ]"
        class="label font-weight-bold label-lg label-inline white-space-nowrap"
      >
        <span :id="`cooperative_inactive_${item.id}`">
          {{
            get(item, 'attributes.status') == 'Active' && get(item, 'attributes.step_status', 0) >= 3
              ? 'Active'
              : 'Not Active'
          }}
        </span>
      </span>
    </b-link>

    <b-popover
      :target="`cooperative_inactive_${item.id}`"
      placement="top"
      triggers="focus"
      custom-class="coop-statuses-popover"
    >
      <!-- To activate cooperative,please complete all steps -->
      <ol class="p-1 m-0">
        <li
          v-for="(step, index) in acitveStates()"
          :key="index"
          class="d-flex align-items-center justify-content-between mb-1"
        >
          {{ step.title }}
          <span v-if="step.state" class="ml-1 label-lg label-inline label label-light-success">
            Active
          </span>
          <span v-else class="ml-2 label-lg label-inline label label-light-danger">
            Inactive
          </span>
        </li>
      </ol>
    </b-popover>
  </div>
</template>

<script>
import { startCase } from 'lodash';

export default {
  name: 'CooperativesPopover',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    acitveStates() {
      const conditions = {
        cooperative_wizard_steps: {
          title: 'Cooperative Wizard Steps',
          state: this.get(this.item, 'attributes.step_status', 0) >= 4,
        },
      };

      for (const index in this.item.attributes.ready_to_payment) {
        conditions[index] = {
          title: startCase(index),
          state: this.item.attributes.ready_to_payment[index],
        };
      }

      return conditions;
    },
  },
};
</script>

<style>
.coop-statuses-popover {
  max-width: 420px;
}
</style>
