var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cs-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"pagination":_vm.pagination,"filterable-name":"Status","filterable-key":"status","filterable-list":_vm.statuses},on:{"filtered":_vm.handelFiltering},scopedSlots:_vm._u([{key:"after-header-filterable",fn:function(){return [_c('b-col',{staticClass:"my-2 my-md-0 ml-auto text-right"},[_c('router-link',{attrs:{"to":{ name: 'cooperatives-create' }}},[_c('b-btn',{staticClass:"font-weight-bolder",attrs:{"variant":"light-primary"}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Add Cooperative ")])],1)],1)]},proxy:true},{key:"item.company_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get(item, 'attributes.company_name'))+" ")]}},{key:"item.active_applicants",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.get(item, 'attributes.active_applicants')))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('cooperatives-popover',{attrs:{"item":item}})]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(_vm.get(item, 'attributes.updated_at')).toLocaleString('en-US', { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', }))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{staticClass:"btn-icon",attrs:{"menu-class":"w-max-content","variant":"clean","right":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"ki ki-bold-more-hor font-size-md pr-0"})]},proxy:true}],null,true)},[_c('ul',{staticClass:"navi navi-hover py-5"},[_c('li',{staticClass:"navi-item"},[_c('router-link',{attrs:{"to":{ name: 'cooperatives-create', params: { cooperativeId: item.id } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"navi-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"navi-icon"},[_c('i',{staticClass:"la la-edit font-size-h1 mr-2"})]),_c('span',{staticClass:"navi-text"},[_vm._v("Edit")])])]}}],null,true)})],1),(_vm.get(item, 'attributes.step_status', 0) >= 3)?[_c('li',{staticClass:"navi-item"},[_c('router-link',{attrs:{"to":{ name: 'cooperatives-view-details', params: { cooperativeId: item.id } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"navi-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"navi-icon"},[_c('i',{staticClass:"la la-eye font-size-h1 mr-2"})]),_c('span',{staticClass:"navi-text"},[_vm._v("View")])])]}}],null,true)})],1),_c('li',{staticClass:"navi-item"},[_c('router-link',{attrs:{"to":{ name: 'cooperatives-view-board-members', params: { cooperativeId: item.id } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"navi-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"navi-icon"},[_c('i',{staticClass:"la la-users font-size-h1 mr-2"})]),_c('span',{staticClass:"navi-text"},[_vm._v("Board Members")])])]}}],null,true)})],1),_c('li',{staticClass:"navi-item"},[_c('router-link',{attrs:{"to":{ name: 'cooperatives-view-applicants', params: { cooperativeId: item.id } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"navi-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"navi-icon"},[_c('i',{staticClass:"la la-gg font-size-h1 mr-2"})]),_c('span',{staticClass:"navi-text"},[_vm._v("Applicants")])])]}}],null,true)})],1)]:_vm._e()],2)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }