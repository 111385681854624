<template>
  <div>
    <cs-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :pagination="pagination"
      @filtered="handelFiltering"
      filterable-name="Status"
      filterable-key="status"
      :filterable-list="statuses"
    >
      <template #after-header-filterable>
        <b-col class="my-2 my-md-0 ml-auto text-right">
          <router-link :to="{ name: 'cooperatives-create' }">
            <b-btn variant="light-primary" class="font-weight-bolder">
              <i class="fas fa-plus"></i>
              Add Cooperative
            </b-btn>
          </router-link>
        </b-col>
      </template>
      <template #[`item.company_name`]="{ item }">
        {{ get(item, 'attributes.company_name') }}
      </template>

      <template #[`item.active_applicants`]="{ item }">
        <span class="font-weight-bolder">{{ get(item, 'attributes.active_applicants') }}</span>
      </template>

      <template #[`item.status`]="{ item }">
        <!-- Statuese Popover -->
        <cooperatives-popover :item="item" />
        <!-- End Statuses Popover -->
      </template>

      <template #[`item.updated_at`]="{ item }">
        {{
          new Date(get(item, 'attributes.updated_at')).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          })
        }}
      </template>

      <template #[`item.actions`]="{ item }">
        <b-dropdown class="btn-icon" menu-class="w-max-content" variant="clean" right no-caret>
          <template v-slot:button-content>
            <i class="ki ki-bold-more-hor font-size-md pr-0"></i>
          </template>
          <ul class="navi navi-hover py-5">
            <li class="navi-item">
              <router-link
                :to="{ name: 'cooperatives-create', params: { cooperativeId: item.id } }"
                v-slot="{ href, navigate }"
              >
                <a class="navi-link" :href="href" @click="navigate">
                  <span class="navi-icon">
                    <i class="la la-edit font-size-h1 mr-2"></i>
                  </span>
                  <span class="navi-text">Edit</span>
                </a>
              </router-link>
            </li>
            <template v-if="get(item, 'attributes.step_status', 0) >= 3">
              <li class="navi-item">
                <router-link
                  :to="{ name: 'cooperatives-view-details', params: { cooperativeId: item.id } }"
                  v-slot="{ href, navigate }"
                >
                  <a class="navi-link" :href="href" @click="navigate">
                    <span class="navi-icon">
                      <i class="la la-eye font-size-h1 mr-2"></i>
                    </span>
                    <span class="navi-text">View</span>
                  </a>
                </router-link>
              </li>
              <li class="navi-item">
                <router-link
                  :to="{ name: 'cooperatives-view-board-members', params: { cooperativeId: item.id } }"
                  v-slot="{ href, navigate }"
                >
                  <a class="navi-link" :href="href" @click="navigate">
                    <span class="navi-icon">
                      <i class="la la-users font-size-h1 mr-2"></i>
                    </span>
                    <span class="navi-text">Board Members</span>
                  </a>
                </router-link>
              </li>
              <li class="navi-item">
                <router-link
                  :to="{ name: 'cooperatives-view-applicants', params: { cooperativeId: item.id } }"
                  v-slot="{ href, navigate }"
                >
                  <a class="navi-link" :href="href" @click="navigate">
                    <span class="navi-icon">
                      <i class="la la-gg font-size-h1 mr-2"></i>
                    </span>
                    <span class="navi-text">Applicants</span>
                  </a>
                </router-link>
              </li>
            </template>
            <!-- <li class="navi-item">
              <a class="navi-link" href="#" @click.prevent="removeItem(item.id)">
                <span class="navi-icon">
                  <i class="la la-trash font-size-h1 mr-2"></i>
                </span>
                <span class="navi-text">Delete</span>
              </a>
            </li> -->
          </ul>
        </b-dropdown>
      </template>
    </cs-table>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import CooperativesPopover from './components/CooperativesPopover';

export default {
  name: 'cooperatives-index',
  components: { CooperativesPopover },
  data() {
    return {
      statuses: [
        { text: 'Active', value: 'Active' },
        { text: 'Not Active', value: 'Not Active' },
      ],
      headers: [
        {
          text: 'Name',
          value: 'company_name',
          sortable: true,
        },
        {
          text: 'Active Applicants',
          value: 'active_applicants',
          align: 'center',
        },
        {
          text: 'Last Edited',
          value: 'updated_at',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'right',
        },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      items: 'cooperatives/items',
      getMessage: 'cooperatives/message',
      pagination: 'cooperatives/pagination',
    }),
  },
  async mounted() {
    await this.handleItems();
  },
  methods: {
    ...mapActions({
      getItems: 'cooperatives/index',
      deleteItem: 'cooperatives/deleteItem',
    }),
    removeItem(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteItem(id);
          toast.fire({
            type: 'success',
            icon: 'success',
            title: 'Cooperative is deleted!',
          });
          await this.handleItems();
        }
      });
    },
    async handelFiltering(filters) {
      const routeQuery = this.$route.query;

      const isSame = this.isSame(routeQuery, filters);

      if (isSame) return;

      await this.$router.replace({ query: filters });

      await this.handleItems();
    },
    handleItems: debounce(async function(e) {
      this.loading = true;

      const query = this.$_.cloneDeep(this.$route.query);
      query.elasticsearch = true;

      if (query.status != undefined) query.where = { status: query.status };

      if (query.per == undefined) query.per = 10;

      if (query.per == -1) query.per = Math.pow(10, 12);

      await this.$await(this.getItems(query));

      this.loading = false;
    }, 400),
  },
};
</script>
